import { useEffect } from 'react';
import dayjs from 'dayjs';
import { useQueryClient } from 'react-query';
import { ContactsTypes, ContactsAPI } from '@frontend/api-contacts';
import { PersonAPI } from '@frontend/api-person';
import { AvatarUpload } from '@frontend/avatar-image-uploader';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useMutation } from '@frontend/react-query-helpers';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import {
  DatePickerField,
  DropdownField,
  FormRow,
  Heading,
  IconButton,
  PhoneField,
  PrimaryButton,
  TextField,
  useForm,
  Text,
} from '@frontend/design-system';
import { useCustomContactFormSlidePanel } from '../../hooks';
import { datePickerStyle, formStyles, modalHeaderStyle, modalStyles } from '../style';

type NewContact = ContactsTypes.NewContactPayload;
type LanguageAPIOption = 'Spanish' | 'English';

const digitsOnly = (str = ''): string => str.replace(/\D/g, '');

const sanitizePhoneNumbers = ({ phone_mobile, phone_home, birthdate, ...rest }: NewContact): NewContact => {
  return {
    ...rest,
    phone_mobile: digitsOnly(phone_mobile),
    phone_home: digitsOnly(phone_home),
    birthdate: birthdate ? dayjs(birthdate).format('YYYY-MM-DD') : '',
  };
};

export const CustomContactForm = () => {
  const queryClient = useQueryClient();
  const { selectedLocationIds } = useAppScopeStore();
  const defaultLocationId = selectedLocationIds[0];

  const { t } = useTranslation('contacts');
  const languageOptionsMap = {
    English: t('English'),
    Spanish: t('Spanish'),
    'No Preference': t('No Preference'),
  } as const;

  const { setShow, context } = useCustomContactFormSlidePanel();
  const { mode, person, locationId: contextLocationId, onSave, onError } = context ?? {};
  const resolvedLocationId = contextLocationId || defaultLocationId;

  const sanitizeLanguage = (language?: string): LanguageAPIOption | null => {
    if (!language || language === 'No Preference') return null;
    if (language === ('English' satisfies LanguageAPIOption)) return 'English';
    if (language === ('Spanish' satisfies LanguageAPIOption)) return 'Spanish';
    return null;
  };

  const { mutateAsync, isLoading: creatingContact } = useMutation({
    mutationFn: (contactDetails: NewContact) => {
      if (mode === 'edit' && person) {
        return ContactsAPI.updateCustomContact(person.PersonID, contactDetails, contextLocationId);
      } else {
        return ContactsAPI.createCustomContact(contactDetails, contextLocationId);
      }
    },
    onSuccess: onSave,
    onError,
  });

  const { formProps, getFieldProps, isComplete, validate, seedValues } = useForm({
    allowInvalidSubmission: false,
    fields: {
      email: { type: 'email', value: person?.Email },
      birthdate: {
        type: 'datePicker',
        value: person?.Birthdate ? dayjs(person?.Birthdate).format('MM/DD/YYYY') : undefined,
      },
      phone_home: { type: 'phone', value: person?.HomePhone },
      gender: { type: 'dropdown', value: person?.Gender },
      first_name: { type: 'text', value: person?.FirstName, required: true },
      last_name: { type: 'text', value: person?.LastName, required: true },
      phone_mobile: { type: 'phone', value: person?.MobilePhone },
      language: { type: 'dropdown', value: person?.Preferences?.Language ?? languageOptionsMap['No Preference'] },
    },
    onSubmit: async ({ language, ...values }) => {
      if (!values.first_name || !values.last_name) {
        validate();
        return;
      }
      const res = await mutateAsync(sanitizePhoneNumbers(values as NewContact));
      mutatePersonPreferences.mutate({ personId: res.data.person_id, language: sanitizeLanguage(language) });
      if (res.data) setShow({ show: false });
    },
  });

  useEffect(() => {
    seedValues({
      phone_mobile: person?.MobilePhone,
    });
  }, [person, seedValues]);

  const mutatePersonPreferences = useMutation({
    mutationFn: ({ personId, language }: { personId: string; language: LanguageAPIOption | null }) =>
      PersonAPI.updatePersonPreferences(
        personId,
        {
          Language: language,
        },
        contextLocationId
      ),
    onSuccess: (_res, req) => queryClient.invalidateQueries({ queryKey: [resolvedLocationId, req.personId] }),
  });

  return (
    <div css={modalStyles}>
      <div css={modalHeaderStyle}>
        <Heading level={2}>{mode === 'edit' ? t('Update Contact') : t('Create New Contact')}</Heading>
        <IconButton label='Close' className='close-btn' onClick={() => setShow({ show: false })}>
          <Icon name='x' />
        </IconButton>
      </div>
      <section css={{ width: '100%', marginTop: theme.spacing(1), display: 'flex', justifyContent: 'center' }}>
        <AvatarUpload
          PersonID={person?.PersonID || ''}
          FirstName={person?.FirstName || ''}
          LastName={person?.LastName || ''}
        />
      </section>
      <form css={formStyles} {...formProps} autoComplete='off'>
        <section style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(1) }}>
          <Text size='medium'>{t('General Information')}</Text>
          <FormRow>
            <TextField {...getFieldProps('first_name')} label={t('first name')} />
          </FormRow>
          <FormRow>
            <TextField {...getFieldProps('last_name')} label={t('last name')} />
          </FormRow>
          <FormRow>
            <DropdownField {...getFieldProps('gender')} label={t('gender')}>
              <DropdownField.Option value='Male'>{t('Male')}</DropdownField.Option>
              <DropdownField.Option value='Female'>{t('Female')}</DropdownField.Option>
              <DropdownField.Option value='Unknown'>{t('Unknown')}</DropdownField.Option>
            </DropdownField>
          </FormRow>
          <FormRow>
            <DatePickerField css={datePickerStyle} {...getFieldProps('birthdate')} label={t('birthdate')} />
          </FormRow>
          <FormRow>
            <DropdownField {...getFieldProps('language')} label={t('Select Language Preference')}>
              {Object.entries(languageOptionsMap).map(([value, translation]) => (
                <DropdownField.Option key={value} value={value} trackingId='contact-2.0-tray-tab-history-langprefs'>
                  {translation}
                </DropdownField.Option>
              ))}
            </DropdownField>
          </FormRow>
        </section>
        <section style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(1) }}>
          <Text size='medium'>{t('Contact Information')}</Text>
          <FormRow>
            <PhoneField {...getFieldProps('phone_mobile')} label={t('mobile phone')} />
          </FormRow>
          <FormRow>
            <PhoneField {...getFieldProps('phone_home')} label={t('home phone')} />
          </FormRow>
          <FormRow>
            <TextField {...getFieldProps('email')} label={t('email')} />
          </FormRow>
        </section>

        <div className='form-action'>
          <PrimaryButton type='submit' disabled={creatingContact || !isComplete} css={{ width: 'auto' }}>
            {t('Save')}
          </PrimaryButton>
        </div>
      </form>
    </div>
  );
};

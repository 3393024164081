import { useEffect, useState } from 'react';
import { Interpolation, Theme } from '@emotion/react';
import { ScheduledSms } from '@weave/schema-gen-ts/dist/schemas/messaging/scheduled/shared/v1/models.pb';
import dayjs from 'dayjs';
import { formatDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import { InboxPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import {
  Button,
  Checkbox,
  ClockIcon,
  DatePickerField,
  FormFieldActionTypes,
  ListRow,
  PopoverMenuItem,
  SendIcon,
  Text,
  TimeField,
  TrashIcon,
  ValidatorFieldState,
  useForm,
} from '@frontend/design-system';

interface ScheduleMenu {
  id: 'tomm' | 'mond' | 'cust';
  title: string;
}

const DATE_VALUE_FORMAT = 'MM/DD/YYYY';
const DATE_VALIDATOR_FORMAT = 'YYYY-MM-DD';
const TIME_VALUE_FORMAT = 'h:mma';
const TIME_VALIDATOR_FORMAT = 'HH:mm:ss';

const dateIsFuture = ({ date, orEqual = false }: { date: string; orEqual?: boolean }) => {
  const formattedDate = formatDate(date, DATE_VALIDATOR_FORMAT);
  const formattedCurrentDate = formatDate(new Date(), DATE_VALIDATOR_FORMAT);

  return formattedDate > formattedCurrentDate || (orEqual && formattedDate === formattedCurrentDate);
};

const timeIsValid = ({ date, time }: { date: string; time: string }) => {
  return dateIsFuture({ date }) ? true : time > formatDate(new Date(), TIME_VALIDATOR_FORMAT);
};

type ScheduleMessagePopoverContentsProps = {
  onSchedule: ({ sendAt, pausable }: { sendAt: string; pausable: boolean }) => void;
  onSendNow: () => void;
  deleteScheduledMessage?: (smsId: string) => void;
  scheduledMessageForEdit?: ScheduledSms;
  disableForm?: boolean;
  trackingIdSuffix?: string;
};

export const ScheduleMessagePopoverContents = ({
  onSchedule,
  scheduledMessageForEdit,
  onSendNow,
  deleteScheduledMessage = () => {},
  disableForm,
  trackingIdSuffix = '',
}: ScheduleMessagePopoverContentsProps) => {
  const { t } = useTranslation('inbox');
  const [selected, setSelected] = useState<string | null>(null);
  const [pausable, setPausable] = useState<boolean>(() => scheduledMessageForEdit?.pausable ?? false);
  const [minTime, setMinTime] = useState<string>('00:00');
  const [showScheduleForm, setShowScheduleForm] = useState<boolean>(false);

  const { getFieldProps, formProps, isComplete, values, validate, seedValues } = useForm({
    allowInvalidSubmission: false,
    fields: {
      date: {
        type: 'datePicker',
        validator: ({ value }) => {
          return dateIsFuture({ date: value, orEqual: true }) ? '' : t('Send date must be in the future');
        },
        required: true,
        minDate: formatDate(new Date(), DATE_VALIDATOR_FORMAT),
        validateOnChange: true,
        value: '',
      },
      time: {
        type: 'time',
        validator: ({ value }, { date }: { date?: ValidatorFieldState<'datePicker'> }) => {
          return !date?.value || timeIsValid({ date: date.value, time: value }) ? '' : t('Time must be in the future');
        },
        required: true,
        validateOnChange: true,
        value: '',
      },
    },
    fieldStateReducer: (state, action) => {
      if (action.type === FormFieldActionTypes.Update && action.payload.name === 'date') {
        const newDate = action.payload.value;
        if (dateIsFuture({ date: newDate })) {
          setMinTime('00:00');
        } else {
          const dateForNextHour = new Date(new Date().getTime() + 3600000);
          setMinTime(formatDate(dateForNextHour, 'HH:00'));
        }
      }
      return state;
    },
  });

  useEffect(() => {
    if (scheduledMessageForEdit?.sendAt) {
      const initScheduleTimestamp = new Date(scheduledMessageForEdit.sendAt);

      // fill up the initial selected value
      let selected = null;
      const now = new Date();
      const tomorrow = new Date(now.getTime() + 86400000);
      const monday = new Date();
      monday.setDate(monday.getDate() + ((1 + 7 - monday.getDay()) % 7));
      if (
        initScheduleTimestamp.toDateString() === tomorrow.toDateString() &&
        initScheduleTimestamp.getHours() === 8 &&
        initScheduleTimestamp.getMinutes() === 0
      )
        selected = 'tomm';
      else if (
        initScheduleTimestamp.toDateString() === monday.toDateString() &&
        initScheduleTimestamp.getHours() === 8 &&
        initScheduleTimestamp.getMinutes() === 0
      )
        selected = 'mond';
      else selected = 'cust';
      setSelected(selected);

      // fill up the initial date and time values
      if (selected === 'cust') {
        const newTime = formatDate(initScheduleTimestamp, TIME_VALUE_FORMAT) ?? '';
        seedValues({ time: newTime });
        if (newTime) validate();

        const newDate = formatDate(initScheduleTimestamp, DATE_VALUE_FORMAT) ?? '';
        seedValues({ date: newDate });
        if (newDate) validate();
      }
    }
  }, [scheduledMessageForEdit?.sendAt]);

  const handleSelect = (id: string) => {
    setSelected(id);
  };

  const handleScheduleSend = () => {
    if (!selected) return;

    let sendAt = '';
    if (selected === 'tomm') {
      const tomorrow = new Date(new Date().getTime() + 86400000);
      const tomorrowMorning = new Date(tomorrow.setHours(8, 0, 0, 0)).toISOString();
      sendAt = tomorrowMorning;
    } else if (selected === 'mond') {
      const now = new Date();
      const monday = new Date();
      monday.setDate(monday.getDate() + ((1 + 7 - monday.getDay()) % 7));

      // If today is Monday and it's past 8AM, set the date to the next Monday
      if (monday.getDay() === now.getDay() && now.getHours() >= 8) {
        monday.setDate(monday.getDate() + 7);
      }

      const mondayMorning = new Date(monday.setHours(8, 0, 0, 0)).toISOString();
      sendAt = mondayMorning;
    } else if (selected === 'cust') {
      const combinationISOString = `${formatDate(values.date, DATE_VALIDATOR_FORMAT)}T${values.time}`;
      sendAt = new Date(combinationISOString).toISOString();
    }

    if (!sendAt) return;
    onSchedule({ sendAt, pausable });
  };

  const isBtnDisabled = !selected || (selected === 'cust' && !isComplete);

  const editScheduleMenus = [
    {
      Icon: SendIcon,
      label: t('Send Now'),
      onClick: onSendNow,
      trackingId: `${InboxPrefixes.Thread}-schedule-send-now-button${trackingIdSuffix}`,
    },
    {
      Icon: ClockIcon,
      label: t('Reschedule Send'),
      onClick: () => setShowScheduleForm(true),
      trackingId: `${InboxPrefixes.Thread}-schedule-reschedule-button${trackingIdSuffix}`,
    },
    {
      Icon: TrashIcon,
      label: t('Delete Message'),
      onClick: () => scheduledMessageForEdit && deleteScheduledMessage(scheduledMessageForEdit?.id),
      trackingId: `${InboxPrefixes.Thread}-schedule-delete-button${trackingIdSuffix}`,
      destructive: true,
    },
  ];

  const scheduleMenus: ScheduleMenu[] = [
    {
      id: 'tomm',
      title: t('Tomorrow Morning at 8:00 AM'),
    },
    {
      id: 'mond',
      title: t('Monday Morning at 8:00 AM'),
    },
    {
      id: 'cust',
      title: t('Custom Time'),
    },
  ];

  const disabledCSS: Interpolation<Theme> = disableForm ? { pointerEvents: 'none', opacity: 0.5 } : {};

  if (!showScheduleForm && scheduledMessageForEdit)
    return (
      <>
        {editScheduleMenus.map((menu) => (
          <PopoverMenuItem
            menuId={menu.trackingId}
            key={menu.trackingId}
            Icon={menu.Icon}
            onClick={menu.onClick}
            destructive={menu.destructive}
            trackingId={menu.trackingId + trackingIdSuffix}
            css={{
              width: '100%',
              cursor: 'pointer',
            }}
          >
            <Text color={menu.destructive ? 'error' : 'default'}>{menu.label}</Text>
          </PopoverMenuItem>
        ))}
      </>
    );

  return (
    <div
      css={{
        maxWidth: '420px',
        ...disabledCSS,
      }}
    >
      <div
        css={{
          '.list-row': {
            borderBottom: theme.spacing(0),
            fontSize: theme.fontSize(16),
            padding: theme.spacing(0.5, 2),
            '> span': {
              width: '2px',
            },
          },
        }}
      >
        <ListRow>
          <ListRow.Content>
            <ListRow.Content.Title>{t('Schedule Send')}</ListRow.Content.Title>
          </ListRow.Content>
        </ListRow>
        {scheduleMenus.map((menu) => (
          <ListRow
            key={menu.id}
            trackingId={`${InboxPrefixes.ScheduledMessages}-option-${menu.id}${trackingIdSuffix}`}
            isSelected={selected === menu.id}
            onClick={() => handleSelect(menu.id)}
          >
            <ListRow.Content>
              <ListRow.Content.Subtitle
                css={{
                  fontWeight: selected === menu.id ? 'bold' : 'normal',
                }}
              >
                {menu.title}
              </ListRow.Content.Subtitle>
              {selected === 'cust' && menu.id === 'cust' && (
                <form {...formProps}>
                  <div
                    css={{
                      padding: theme.spacing(2, 0),
                      display: 'flex',
                      gap: theme.spacing(1),
                      justifyContent: 'center',
                    }}
                  >
                    <DatePickerField
                      {...getFieldProps('date')}
                      onChange={(e) => {
                        getFieldProps('date').onChange(e);
                        if (values.time) validate();
                      }}
                      label={t('Date')}
                    />
                    <Text color='light' css={{ paddingTop: theme.spacing(1) }}>
                      {t('at')}
                    </Text>
                    <TimeField {...getFieldProps('time')} label={t('Time')} interval={60} minTime={minTime} />
                    <Text color='light' css={{ paddingTop: theme.spacing(1) }}>
                      {dayjs().format('z')}
                    </Text>
                  </div>
                </form>
              )}
            </ListRow.Content>
          </ListRow>
        ))}
      </div>
      <div
        css={{
          padding: `${theme.spacing(0)} ${theme.spacing(2)}`,
          borderTop: `${theme.spacing(1 / 8)} solid ${theme.colors.neutral20}`,
          marginTop: theme.spacing(2),
        }}
      >
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => setPausable(!pausable)}
          data-trackingid={`${InboxPrefixes.Thread}-pause-scheduled-message-btn${trackingIdSuffix}`}
        >
          <Checkbox
            id='schedule-pause-checkbox'
            value={pausable}
            onChange={() => {}}
            onFocus={() => {}}
            onBlur={() => {}}
            aria-invalid={false}
            active={false}
            error={false}
            name='schedule-pause-checkbox'
          />
          <div
            css={{
              padding: theme.spacing(2),
            }}
          >
            <Text color='subdued'>{t('Pause message if inbound message is received before send time')}</Text>
          </div>
        </div>
        <Button
          variant='primary'
          disabled={isBtnDisabled}
          onClick={handleScheduleSend}
          trackingId={`${InboxPrefixes.Thread}-schedule-send-button${trackingIdSuffix}`}
        >
          {t('Schedule Send')}
        </Button>
      </div>
    </div>
  );
};

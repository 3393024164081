import { useEffect } from 'react';
import { css } from '@emotion/react';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { ContentLoader } from '@frontend/design-system';
import { CHAT_AREA_WIDTH } from '../../constants';
import { useTeamChatStore } from '../../providers';
import { ConversationBody } from './conversation-body';
import { ConversationTitle } from './conversation-title';

export const Conversation = () => {
  const {
    activeConversation,
    activeChannelId,
    setActiveChannelId,
    setActiveConversation,
    conversations,
    isChatListExpanded,
  } = useTeamChatStore([
    'activeConversation',
    'activeChannelId',
    'setActiveChannelId',
    'setActiveConversation',
    'conversations',
    'isChatListExpanded',
  ]);
  const { t } = useTranslation('team-chat');

  const width = activeConversation || activeChannelId ? CHAT_AREA_WIDTH : 0;

  /* 
    Adding a useEffect here to check if we have an activeChannelId. This activeChannelId tells us that we have a new message
    or etc event from a channel that does not exists in out chat list. This means that we have to fetch it from stream.
    So instead of fetching it we wait for the client to update the conversations and then get the data from the updated
    conversations. In this way we don't an extra API call to stream and let the stream client handle it via events.
    NOTE: this will only happen in case when the client is disconnected. 
   */
  useEffect(() => {
    if (activeChannelId && !activeConversation) {
      const conversation = [...conversations.dm, ...conversations.groups, ...conversations.unread].find(
        (chat) => chat.channelId === activeChannelId
      );
      if (conversation) {
        setActiveConversation(conversation);
        setActiveChannelId();
      }
    }
  }, [activeChannelId, conversations]);

  return (
    <AnimatePresence>
      <motion.section
        css={[styles.container, isChatListExpanded && styles.containerBorder]}
        initial={{ width }}
        animate={{ width }}
        exit={{ width: 0 }}
        transition={{ duration: 0.3, type: 'keyframes' }}
        layout
      >
        {activeChannelId && !activeConversation && (
          <div css={{ width: CHAT_AREA_WIDTH }}>
            <ContentLoader message={t('Fetching latest messages')} show />
          </div>
        )}
        {activeConversation && (
          <>
            <ConversationTitle />
            <ConversationBody />
          </>
        )}
      </motion.section>
    </AnimatePresence>
  );
};

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
    backgroundColor: theme.colors.white,
  }),
  containerBorder: {
    borderLeft: `1px solid ${theme.colors.neutral10}`,
  },
};

import { useState } from 'react';
import { css } from '@emotion/react';
import { ReferenceType } from '@floating-ui/react';
import { ErrorBoundary } from '@sentry/react';
import { useFeatureFlagShallowStore } from '@frontend/shared';
import {
  SoftphonePopout,
  useSoftphoneWidgetControl,
  useSoftphoneCallState,
  CallBar,
  SoftphoneIconButton,
  SoftphoneFeedbackCallRating,
  FeedbackModal,
} from '@frontend/softphone2';
import { useSoftphoneInnerManager } from '@frontend/weave-softphone-manager';
import { theme } from '@frontend/theme';
import { useModalControl, useTooltip } from '@frontend/design-system';

type Props = {
  allowTrayView: boolean;
  allowButtonView: boolean;
  allowPopout: boolean;
};
export const SoftphoneWrap = ({ allowTrayView, allowButtonView, allowPopout }: Props) => {
  const isMounted = useSoftphoneInnerManager((ctx) => ctx?.isMounted);
  const isLoading = useSoftphoneInnerManager((ctx) => ctx?.isLoading);
  const restart = useSoftphoneInnerManager((ctx) => ctx?.onRestart);
  const providersError = useSoftphoneInnerManager((ctx) => ctx?.error);
  const primaryCall = useSoftphoneCallState((ctx) => ctx?.primaryCall);
  const isOpen = useSoftphoneWidgetControl((ctx) => ctx?.isOpen);
  const { flagValues } = useFeatureFlagShallowStore('flagValues');
  const isSoftphoneCallFeedbackEnabled = flagValues['softphone-call-feedback'];

  const [isShowingError, setIsShowingError] = useState(false);
  if (!isMounted) {
    return null;
  }

  const showCallBar = allowTrayView && !providersError && !!primaryCall;
  const showButton = allowButtonView && !showCallBar;

  //TODO: this is very contextual logic. We should make this more generic.
  const isStyled = allowButtonView && (showButton || showCallBar);
  const {
    Tooltip,
    tooltipProps,
    refs,
    setOpen: setSoftphoneFeedbackOpen,
  } = useTooltip({
    placement: 'bottom-start',
    offset: -1,
  });
  const { modalProps, openModal } = useModalControl();

  return (
    <ErrorBoundary
      fallback={({ error }) => (
        <>
          <Wrapper styled={allowButtonView} setReference={refs.setReference}>
            {allowButtonView && (
              <SoftphoneIconButton error={error instanceof Error ? error : new Error('Unknown error has occurred!')} />
            )}
            {allowPopout && isOpen && (
              <SoftphonePopout
                error={error instanceof Error ? error : new Error('unknown error occurred!')}
                restart={restart}
                isLoading={isLoading}
                isMounted={isMounted}
              />
            )}
          </Wrapper>
        </>
      )}
    >
      <Wrapper styled={isStyled} setReference={refs.setReference}>
        {showButton && (
          <SoftphoneIconButton
            error={providersError}
            onClick={providersError ? () => setIsShowingError(!isShowingError) : undefined}
          />
        )}
        {showButton && isSoftphoneCallFeedbackEnabled && (
          <Tooltip {...tooltipProps}>
            <SoftphoneFeedbackCallRating openModal={openModal} setOpen={setSoftphoneFeedbackOpen} />
          </Tooltip>
        )}
        <FeedbackModal {...modalProps} />
        {showCallBar && (
          <CallBar
            css={
              !isStyled
                ? css`
                    border-radius: 0;
                    color: white;
                    border: 1px solid ${theme.colors.neutral50};
                  `
                : null
            }
          />
        )}
        {allowPopout && (!providersError || (providersError && isShowingError)) && (
          <SoftphonePopout error={providersError} restart={restart} isLoading={isLoading} isMounted={isMounted} />
        )}
      </Wrapper>
    </ErrorBoundary>
  );
};

const Wrapper = ({
  styled,
  children,
  setReference,
}: {
  styled: boolean;
  children: React.ReactNode;
  setReference: (node: ReferenceType | null) => void;
}) => {
  if (!styled) {
    return (
      <div
        ref={setReference}
        css={css`
          position: relative;
        `}
      >
        {children}
      </div>
    );
  }
  return (
    <div
      ref={setReference}
      css={css`
        height: 100%;
        display: flex;
        align-items: center;
        position: relative;
        padding: ${theme.spacing(0.5)};
      `}
    >
      {children}
    </div>
  );
};

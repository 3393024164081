import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css({
  borderWidth: `1px 0 1px 1px`,
  borderColor: theme.colors.neutral10,
  borderStyle: 'solid',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
});

export const chatContentStyle = css({
  display: 'flex',
  flex: 1,
  overflow: 'hidden',
  position: 'relative',
});

export const paddedStyle = css({ padding: theme.spacing(4), width: '434px' });

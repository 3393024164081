import { i18next, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import {
  ChecklistField,
  Modal,
  ModalControlModalProps,
  Text,
  TextareaField,
  useFormField,
} from '@frontend/design-system';
import { useSoftphoneEventsEmitter } from '../../providers/softphone-events-provider';
import { FeedbackEvents } from '../../providers/softphone-metrics-provider';

type FeedbackOptions =
  | 'Poor sound quality'
  | 'No sound'
  | 'Call dropped'
  | 'Slow response, app freezing'
  | 'Other (explain below)';

const options: Record<string, FeedbackOptions> = {
  POOR_SOUND_QUALITY: i18next.t('Poor sound quality', { ns: 'phone' }),
  NO_SOUND: i18next.t('No sound', { ns: 'phone' }),
  CALL_DROPPED: i18next.t('Call dropped', { ns: 'phone' }),
  SLOW_RESPONSE: i18next.t('Slow response, app freezing', { ns: 'phone' }),
  OTHER: i18next.t('Other (explain below)', { ns: 'phone' }),
};

export const FeedbackModal = ({ ...modalProps }: ModalControlModalProps) => {
  const { t } = useTranslation('phone');
  const { emit } = useSoftphoneEventsEmitter();

  const feedbackFieldProps = useFormField({
    type: 'checklist',
    value: [''],
  });

  const messageFieldProps = useFormField({
    type: 'text',
  });

  const onClose = () => {
    emit('call.feedback_requested', {
      callRating: 'dismiss',
      callID: '',
    });
    modalProps.onClose();
  };

  const onSave = () => {
    const feedback: FeedbackEvents = {
      callRating: 'negative',
    };
    if (feedbackFieldProps.value.includes('Poor sound quality')) {
      feedback.callFeedbackPoorSoundQuality = true;
    }
    if (feedbackFieldProps.value.includes('No sound')) {
      feedback.callFeedbackNoSound = true;
    }
    if (feedbackFieldProps.value.includes('Call dropped')) {
      feedback.callFeedbackCallDropped = true;
    }
    if (feedbackFieldProps.value.includes('Slow response, app freezing')) {
      feedback.callFeedbackAppUnresponsive = true;
    }
    if (!!messageFieldProps.value || feedbackFieldProps.value.includes('Other (explain below)')) {
      feedback.callFeedbackOther = true;
    }
    if (!!messageFieldProps.value) {
      feedback.callFeedback = messageFieldProps.value;
    }
    emit('call.feedback_requested', {
      callID: '',
      ...feedback,
    });
    modalProps.onClose();
  };

  return (
    <Modal {...modalProps} maxWidth={600} minWidth={600}>
      <Modal.Header onClose={onClose}>{t('Provide Additional Feedback')}</Modal.Header>
      <Modal.Body>
        <Text>{t('Please tell us what went wrong.')}</Text>
        <ChecklistField {...feedbackFieldProps} label='' name='softphone-feedback'>
          {Object.values(options).map((option) => (
            <ChecklistField.Option key={option} name={option}>
              {option}
            </ChecklistField.Option>
          ))}
        </ChecklistField>
        <div css={{ padding: theme.spacing(1.5, 0) }}>
          <TextareaField label={t('Feedback (Optional)')} name='softphone-call-feedback' {...messageFieldProps} />
        </div>
      </Modal.Body>
      <Modal.Actions disablePrimary={!feedbackFieldProps.value} primaryLabel={t('Submit')} onPrimaryClick={onSave} />
    </Modal>
  );
};

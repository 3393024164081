import { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import {
  AddressElement,
  ExpressCheckoutElement,
  LinkAuthenticationElement,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {
  StripeAddressElementChangeEvent,
  StripeError,
  StripeExpressCheckoutElementConfirmEvent,
  StripePaymentElementChangeEvent,
} from '@stripe/stripe-js';
import { useSearch } from '@tanstack/react-location';
import { isFunction } from 'lodash-es';
import ReCAPTCHA from 'react-google-recaptcha-enterprise';
import { PaymentMethodTypeEnum } from '@frontend/api-weave-pay';
import env from '@frontend/env';
import { HttpError } from '@frontend/fetch';
import { useTranslation } from '@frontend/i18n';
import {
  Accordion,
  CheckboxField,
  ConfirmationModal,
  CreditCardIcon,
  FormFieldActionTypes,
  FullscreenModal,
  IconButton,
  IdIcon,
  MoneyField,
  PreviewIcon,
  PrimaryButton,
  SecondaryButton,
  Text,
  TextField,
  TextLink,
  UserIcon,
  useForm,
  useFormField,
  useModalControl,
  useAlert,
} from '@frontend/design-system';
import { stepOption, useStepAccordian } from '../../hooks';
import * as styles from '../../styles/weave-pay.styles';
import { Payment, WeavePayLocationInfo, RequestForPaymentType } from '../../types';
import { convertToCents, displayAmount } from '../../utils';
import StripeIcon from './assets/powered-by-stripe.svg?react';
import WeavePoweredLogo from './assets/powered-by-weave.svg?react';
import { Auth } from './Auth';
import { IconTextWrapper, LocationInformation } from './molecules';
import { SuccessComponent } from './Success';
import { TTPSuccessComponent } from './SuccessTTP';

const minAmountDollars = 0.5;
const minACHAmountDollars = 250;
const maxACHAmountDollars = 100000;
const reCaptchaKey = env.PAY_CAPTCHAV2_SITE_KEY;

type AffirmRedirectParams = {
  payment_intent_client_secret?: string;
  payment_intent?: string;
  amount?: number;
  receiptEmail?: string;
  firstName?: string;
  lastName?: string;
};
export type FailurePayment = StripeError | Error | HttpError;
export type SuccessPayment = Pick<Payment, 'amount' | 'patientName' | 'receiptEmail'> & { paymentId?: string };
export type SuccessHandler = (paymentDetails: SuccessPayment) => void;
export type FailureHandler = (error: FailurePayment) => void;

interface PaymentPortalProps {
  locationData?: WeavePayLocationInfo;
  setupPaymentIntent: (
    payment: Payment,
    captchaToken: string | null
  ) => Promise<{ clientSecret?: string; paymentId?: string }>;
  isTTP?: boolean;
  requestForPayment?: RequestForPaymentType;
  requestedAmount?: number;
  amountEditable?: boolean;
  showLocationInfo?: boolean;
  onSuccess?: SuccessHandler;
  onFailure?: FailureHandler;
  refetchData?: () => void;
}

export const PaymentPortal = ({
  locationData,
  setupPaymentIntent,
  isTTP = false,
  requestForPayment,
  requestedAmount,
  amountEditable = true,
  showLocationInfo = true,
  onSuccess,
  onFailure,
  refetchData,
}: PaymentPortalProps) => {
  const { t } = useTranslation('weave-pay');
  const stripe = useStripe();
  const elements = useElements();
  const params: AffirmRedirectParams = useSearch();
  const alerts = useAlert();

  const recaptchaRef = useRef<ReCAPTCHA>() as RefObject<ReCAPTCHA>;
  const { modalProps: confirmModalProps, triggerProps: confirmTriggerProps } = useModalControl();

  const {
    modalProps: attachmentAuthModalProps,
    openModal: openAttachmentAuthModal,
    closeModal: closeAttachmentAuthModal,
  } = useModalControl();

  // const { ...replaceCardOnFileModalProps } = useModalControl();
  const [successPayment, setSuccessPayment] = useState<SuccessPayment | null>(null);
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const fromWeavePayPortal = !isFunction(onSuccess);
  const paymentSuccessfullyCompleted = !!successPayment;
  const displaySuccessMessage = paymentSuccessfullyCompleted && fromWeavePayPortal;
  const resetSuccessPayment = () => fromWeavePayPortal && setSuccessPayment(null);

  const [paymentElementComplete, setPaymentElementComplete] = useState<boolean>(false);
  const [addressElementComplete, setAddressElementComplete] = useState<boolean>(false);
  const [detailsStepComplete, setDetailsStepComplete] = useState<boolean>(false);
  const [paymentIntentId, setPaymentIntentId] = useState<string | undefined>(undefined);
  const [paymentClientSecret, setPaymentClientSecret] = useState<string | undefined>(undefined);
  const [locationInfo, setLocationInfo] = useState<Partial<WeavePayLocationInfo>>({
    email: '',
    name: '',
    phoneNumber: '',
  });

  const [payment, setPayment] = useState<Payment>({
    amount: 0,
    cardholderName: '',
    paymentMethodType: PaymentMethodTypeEnum.CARD,
    patientName: {
      firstName: '',
      lastName: '',
    },
    receiptEmail: '',
    setupFutureUsage: false,
  });

  const [isAffirm, setIsAffirm] = useState<boolean>(false);
  const [isACH, setIsACH] = useState<boolean>(false);
  const [isLink, setIsLink] = useState<boolean>(false);
  const [isExpress, setIsExpress] = useState<boolean>(false);

  const enableSavingAch = !!requestForPayment?.enableSavingAch;
  const isSavingEnabledMethod = !isAffirm && !isExpress && (enableSavingAch || !isACH);
  const shouldShowSavePaymentMethodOnFile = isTTP && requestForPayment?.paymentMethods && isSavingEnabledMethod;
  const shouldSavePaymentMethodOnFileFieldProps = useFormField({ type: 'checkbox' });
  const skipAttachmentAuth = !!requestForPayment?.skipAttachmentAuth;

  useEffect(() => {
    setPayment({ ...payment, setupFutureUsage: shouldSavePaymentMethodOnFileFieldProps.value });
    elements?.update({
      setupFutureUsage: shouldSavePaymentMethodOnFileFieldProps.value && isSavingEnabledMethod ? 'off_session' : null,
    });
  }, [shouldSavePaymentMethodOnFileFieldProps.value]);

  const {
    getFieldProps,
    reset: detailsReset,
    values,
    seedValues,
  } = useForm({
    fields: {
      firstName: {
        type: 'text',
        required: true,
      },
      lastName: {
        type: 'text',
        required: true,
      },
      amount: {
        type: 'money',
        required: true,
        value: requestedAmount?.toString() ?? '',
        min: minAmountDollars,
        max: requestedAmount ?? Infinity,
      },
    },
    fieldStateReducer: (state, action) => {
      if (action.type === FormFieldActionTypes.Focus) {
        resetSuccessPayment();
      }
      return {
        ...state,
      };
    },
  });

  useEffect(() => {
    if (isAffirm) {
      setPayment({ ...payment, paymentMethodType: PaymentMethodTypeEnum.AFFIRM, setupFutureUsage: false });
    } else if (isACH) {
      setPayment({
        ...payment,
        paymentMethodType: PaymentMethodTypeEnum.ACH,
        setupFutureUsage: enableSavingAch ? payment.setupFutureUsage : false,
      });
    } else if (isLink) {
      setPayment({ ...payment, paymentMethodType: PaymentMethodTypeEnum.LINK, setupFutureUsage: false });
    } else {
      setPayment({ ...payment, paymentMethodType: PaymentMethodTypeEnum.CARD });
    }
  }, [isAffirm, isACH, isLink]);

  useEffect(() => {
    setPaymentIntentId(undefined);
  }, [payment.paymentMethodType]);

  useEffect(() => {
    setPaymentElementComplete(false);
    setDetailsStepComplete(false);
  }, [payment.amount, payment.patientName, payment.receiptEmail]);

  useEffect(() => {
    setPayment({ ...payment, patientName: { lastName: values.lastName, firstName: values.firstName } });
  }, [values.firstName, values.lastName]);

  useEffect(() => {
    setPayment({ ...payment, amount: Number(values.amount) });
  }, [values.amount]);

  useEffect(() => {
    if (params.payment_intent) {
      getPaymentIntent();
    }
  }, [params?.payment_intent, stripe]);

  const getPaymentIntent = async () => {
    if (!stripe || !params.payment_intent_client_secret) {
      return;
    }
    const { paymentIntent } = await stripe.retrievePaymentIntent(params?.payment_intent_client_secret || '');
    if (params.payment_intent && paymentIntent?.last_payment_error?.type) {
      alerts.error({
        autoDismissAfter: 12000,
        message: t('There was an issue with your Affirm setup. Please use a different payment method and try again.'),
      });
      seedValues({ amount: params?.amount?.toString() });
      setPayment({ ...payment, amount: params.amount, receiptEmail: params?.receiptEmail });
    } else if (paymentIntent?.status === 'succeeded') {
      const newSuccessPayment = {
        ...successPayment,
        amount: params.amount,
        patientName: { firstName: params.firstName, lastName: params?.lastName },
        receiptEmail: params.receiptEmail,
      };
      setSuccessPayment(newSuccessPayment);
      onSuccess?.(newSuccessPayment);
    }
  };

  const isAmountValid = !!payment.amount && !getFieldProps('amount').error;

  useEffect(() => {
    getLocationData();
  }, [locationData, requestForPayment]);

  const getLocationData = () => {
    const headerInfo = {
      email: locationData?.email || requestForPayment?.location?.email,
      name: locationData?.name || requestForPayment?.location?.name,
      phoneNumber: locationData?.phoneNumber || requestForPayment?.location?.phoneNumber,
    };
    setLocationInfo(headerInfo);
  };

  const isPatientNameValid = !!payment.patientName?.firstName && !!payment.patientName.lastName;
  const isDetailsStepValid = isTTP
    ? isPatientNameValid && isAmountValid
    : isPatientNameValid && !!payment.receiptEmail && isAmountValid;

  const paymentSteps: stepOption[] = [
    { value: 'details', valid: isDetailsStepValid },
    {
      value: 'cardInfo',
      disabled: !isDetailsStepValid || !detailsStepComplete,
      valid: paymentElementComplete || isLink,
    },
    { value: 'billingInfo', disabled: !paymentElementComplete, valid: addressElementComplete },
  ];

  const { stepProps, isActiveStep, isValidStep, isStepDisabled, setActiveStep } = useStepAccordian({
    steps: paymentSteps,
  });

  const getCardName = (event: StripeAddressElementChangeEvent) => {
    if (event.complete) {
      const cardholderName = event.value.name;
      setPayment({ ...payment, cardholderName });
      setAddressElementComplete(true);
    } else {
      setAddressElementComplete(false);
    }
  };

  const getIfPaymentIsValid = (achPayment: boolean) => {
    let isValidPayment = true;
    if (achPayment && !!payment.amount) {
      if (payment.amount < minACHAmountDollars) {
        isValidPayment = false;
        alerts.error({
          autoDismissAfter: 8000,
          message: 'Payment amount should not be less than $250 for ACH.',
        });
      } else if (payment.amount > maxACHAmountDollars) {
        isValidPayment = false;
        alerts.error({
          autoDismissAfter: 8000,
          message: 'Payment amount should not be more than $100,000 for ACH.',
        });
      }
    }
    return isValidPayment;
  };

  const handleDetailsStep = async () => {
    setPaymentElementComplete(false);
    setActiveStep(2);
    elements?.update({ amount: convertToCents(payment?.amount || 0) });
    const isValidPayment = getIfPaymentIsValid(isACH);
    setDetailsStepComplete(true);
    if (isValidPayment) {
      if (payment.paymentMethodType === PaymentMethodTypeEnum.AFFIRM) {
        setPaymentElementComplete(true);
      }
    }
  };

  const handlePaymentChange = (event: StripePaymentElementChangeEvent) => {
    const achPayment = event.value.type === 'us_bank_account';

    setIsAffirm(event.value.type === 'affirm');
    setIsACH(achPayment);
    setIsLink(event.value.type === 'link');

    const isValidPayment = getIfPaymentIsValid(achPayment);

    setPaymentElementComplete(event.complete && isValidPayment);
  };

  const clearForm = () => {
    const linkElement = elements?.getElement('linkAuthentication') || null;
    const paymentElement = elements?.getElement('payment') || null;
    const addressElement = elements?.getElement('address') || null;

    setPayment({
      amount: 0,
      cardholderName: '',
      patientName: {
        firstName: '',
        lastName: '',
      },
      receiptEmail: '',
      setupFutureUsage: false,
    });

    detailsReset();
    paymentElement && paymentElement?.clear();
    addressElement && addressElement?.clear();
    linkElement && linkElement?.clear();
    recaptchaRef.current?.reset();
    setActiveStep(1);
    setPaymentClientSecret(undefined);
    setPaymentIntentId(undefined);
  };

  const handleCaptcha = (token: string | null) => {
    if (token) {
      setCaptchaToken(token);
    } else {
      setCaptchaToken(null);
    }
  };

  const handlePaymentIntent = async () => {
    if (!paymentIntentId) {
      const { clientSecret, paymentId } = await setupPaymentIntent(payment, captchaToken || null);
      setPaymentClientSecret(clientSecret);
      setPaymentIntentId(paymentId);
      return { clientSecret, paymentId };
    } else {
      return { clientSecret: paymentClientSecret, paymentId: paymentIntentId };
    }
  };

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      const errMsg = t('Stripe not loaded yet. Try again!');
      alerts.error({
        autoDismissAfter: 12000,
        message: errMsg,
      });
      onFailure?.(new Error(errMsg));
      return;
    }

    elements.update({ setupFutureUsage: shouldSavePaymentMethodOnFileFieldProps.value ? 'off_session' : undefined });

    const { clientSecret, paymentId } = await handlePaymentIntent();

    try {
      const { error: submitError } = await elements.submit();
      if (submitError) {
        alerts.warning({
          autoDismissAfter: 12000,
          message: t('Invalid information provided. Please fix the errors and resubmit.'),
        });
        setActiveStep(1);
      }
    } catch (err) {
      alerts.warning({
        autoDismissAfter: 12000,
        message: t('Invalid information provided. Please fix the errors and resubmit.'),
      });
    }
    let error: StripeError | Error | HttpError | undefined;
    try {
      if (clientSecret) {
        const { error: confirmPaymentError } = await stripe.confirmPayment({
          elements,
          clientSecret,
          redirect: 'if_required',
          confirmParams: {
            return_url: `${window.location.href}?amount=${payment.amount}&firstName=${payment.patientName?.firstName}&lastName=${payment.patientName?.lastName}&receiptEmail=${payment.receiptEmail}`,
          },
        });
        error = confirmPaymentError;
        if (!error) {
          const newSuccessPayment = {
            ...successPayment,
            amount: payment.amount,
            patientName: { ...payment.patientName },
            receiptEmail: payment.receiptEmail,
            paymentId: paymentId || '',
          };
          setSuccessPayment(newSuccessPayment);
          onSuccess?.(newSuccessPayment);
          if (fromWeavePayPortal) clearForm();
        } else if (error.type === 'card_error') {
          alerts.error({
            autoDismissAfter: 12000,
            message: error.message || t('Error processing your card. Please check your information and try again.'),
          });
          recaptchaRef.current?.reset();
          setActiveStep(2);
        } else if (error.param === 'setup_future_usage') {
          alerts.error({
            autoDismissAfter: 12000,
            message: t('There was an error processing your payment. Wait 30 seconds and try again.'),
          });
        } else if (error) {
          alerts.error({
            autoDismissAfter: 12000,
            message: t('Error submitting payment. Please try again.'),
          });
        }
        if (error) onFailure?.(error);
      }
    } catch (err) {
      alerts.error({
        autoDismissAfter: 12000,
        message: t('Error processing payment with Stripe. Try again!'),
      });
      if (err instanceof Error) {
        error = err;
        onFailure?.(err);
      }
    } finally {
      if (error && isTTP) {
        refetchData?.();
      }
    }
  };

  const disableSubmit = useMemo(
    () =>
      !(isValidStep(1) && isValidStep(2) && isValidStep(3)) ||
      (!isTTP && !captchaToken) ||
      paymentSuccessfullyCompleted,
    [captchaToken, paymentSuccessfullyCompleted, isValidStep(1), isValidStep(2), isValidStep(3)]
  );

  const disableActiveStep2 =
    !isValidStep(2) ||
    paymentSuccessfullyCompleted ||
    (isACH && !!payment.amount && (payment.amount < minACHAmountDollars || payment.amount > maxACHAmountDollars));

  const handleExpressElementComplete = (event: StripeExpressCheckoutElementConfirmEvent) => {
    setIsExpress(true);
    if (event.expressPaymentType === 'link') {
      setIsLink(true);
    }
    handleSubmit();
  };

  return (
    <div css={styles.pageContainer}>
      <div css={styles.portalContainer}>
        <div css={styles.portalHeader}>
          {!isTTP && displaySuccessMessage && <SuccessComponent payment={successPayment} />}
          {locationInfo && showLocationInfo && (
            <LocationInformation
              name={locationInfo.name ?? ''}
              email={locationInfo.email ?? ''}
              phoneNumber={locationInfo.phoneNumber ?? ''}
            />
          )}
          {requestedAmount && !displaySuccessMessage && (
            <div css={styles.invoiceCard}>
              <div css={styles.requestedAmount}>
                <Text color='light' size='medium' css={styles.noMargin}>
                  {t('Requested Amount')}
                </Text>
                <Text size='large' css={styles.noMargin}>
                  {displayAmount(requestedAmount)}
                </Text>
              </div>
              {requestForPayment?.attachment && requestForPayment?.hasBirthDate && (
                <form
                  method='post'
                  action={skipAttachmentAuth ? requestForPayment?.attachment : ''}
                  target={skipAttachmentAuth ? '_blank' : ''}
                  onSubmit={(e) => !skipAttachmentAuth && e.preventDefault()}
                >
                  <IconButton
                    label={t('View Invoice')}
                    showLabelAlways
                    type='submit'
                    css={styles.attachmentContainer}
                    onClick={() => !skipAttachmentAuth && openAttachmentAuthModal()}
                  >
                    <PreviewIcon />
                  </IconButton>
                </form>
              )}
            </div>
          )}
        </div>
        {isTTP && displaySuccessMessage ? (
          <TTPSuccessComponent payment={successPayment} />
        ) : (
          <>
            <Accordion isMulti shouldRenderBody {...stepProps.accordian} variant='bordered' css={styles.accordionBase}>
              <Accordion.Item {...stepProps.step(1).item}>
                <Accordion.Header {...stepProps.step(1).header}>
                  <IconTextWrapper>
                    <UserIcon />
                    {!isValidStep(1) || isActiveStep(1) ? (
                      <Text
                        weight={isStepDisabled(1) ? 'medium' : 'bold'}
                        textAlign='center'
                        css={isActiveStep(1) ? styles.accordionHeader : styles.noMargin}
                      >
                        {t('Payment Details')}
                      </Text>
                    ) : (
                      <div css={styles.paymentDetailsHeader}>
                        <Text weight='bold' textAlign='center' css={styles.noMargin}>
                          {`${payment.patientName?.firstName} ${payment.patientName?.lastName}`} •
                        </Text>
                        <Text color='light' css={styles.noMargin}>
                          {displayAmount(payment?.amount || 0)}
                        </Text>
                      </div>
                    )}
                  </IconTextWrapper>
                </Accordion.Header>
                <Accordion.Body>
                  <form id='patientDetailsForm'>
                    <LinkAuthenticationElement
                      onFocus={() => resetSuccessPayment()}
                      onChange={(event) => {
                        setPayment({
                          ...payment,
                          receiptEmail: event.value.email,
                          paymentMethodType: PaymentMethodTypeEnum.LINK,
                        });
                      }}
                      options={{ defaultValues: { email: params?.receiptEmail || '' } }}
                    />
                    <div css={styles.patientNameRow}>
                      <TextField
                        className='firstName'
                        label={t('Patient First Name*')}
                        {...getFieldProps('firstName')}
                        hasNonFloatingLabel
                        readOnly={paymentSuccessfullyCompleted}
                      />
                      <TextField
                        label={t('Last Name*')}
                        {...getFieldProps('lastName')}
                        hasNonFloatingLabel
                        readOnly={paymentSuccessfullyCompleted}
                      />
                    </div>
                    {amountEditable && (
                      <div css={[styles.paymentAmountRow, styles.inputField]}>
                        <MoneyField
                          label={t('Payment Amount*')}
                          css={styles.fullWidth}
                          {...getFieldProps('amount')}
                          hasNonFloatingLabel
                          disabled={paymentSuccessfullyCompleted}
                        />
                        {!paymentSuccessfullyCompleted &&
                          requestedAmount &&
                          Number(values.amount) !== requestedAmount && (
                            <TextLink onClick={() => seedValues({ amount: displayAmount(requestedAmount) })}>
                              {t('Reset to full amount')}
                            </TextLink>
                          )}
                      </div>
                    )}
                    <div css={styles.buttonRow}>
                      <SecondaryButton
                        disabled={!isValidStep(1) || paymentSuccessfullyCompleted}
                        onClick={handleDetailsStep}
                        className='nextButton'
                        size='large'
                      >
                        {t('Next')}
                      </SecondaryButton>
                    </div>
                  </form>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item {...stepProps.step(2).item}>
                <Accordion.Header {...stepProps.step(2).header}>
                  <IconTextWrapper>
                    <CreditCardIcon />
                    {isValidStep(2) || isActiveStep(2) ? (
                      <Text
                        weight={isStepDisabled(2) ? 'medium' : 'bold'}
                        textAlign='center'
                        css={isActiveStep(2) ? styles.accordionHeader : styles.noMargin}
                      >
                        {t('Payment Method')}
                      </Text>
                    ) : (
                      <Text color='light' css={styles.noMargin}>
                        {t(`Enter ${isACH ? 'Account' : 'credit card'} details`)}
                      </Text>
                    )}
                  </IconTextWrapper>
                </Accordion.Header>
                <Accordion.Body>
                  {isTTP && (
                    <ExpressCheckoutElement onConfirm={handleExpressElementComplete} css={{ marginBottom: '24px' }} />
                  )}
                  <PaymentElement onChange={handlePaymentChange} options={{ readOnly: paymentSuccessfullyCompleted }} />
                  {shouldShowSavePaymentMethodOnFile && (
                    <CheckboxField
                      css={styles.checkboxInput}
                      {...shouldSavePaymentMethodOnFileFieldProps}
                      name='paymentMethodOnfile'
                      label={t(`Save ${isACH ? 'account' : 'card'} on file?`)}
                      labelPlacement='right'
                      disabled={paymentSuccessfullyCompleted}
                    />
                  )}
                  <div css={styles.buttonRow}>
                    <SecondaryButton
                      onClick={() => setActiveStep(3)}
                      disabled={disableActiveStep2}
                      className='nextButton'
                      size='large'
                    >
                      {t('Next')}
                    </SecondaryButton>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item {...stepProps.step(3).item}>
                <Accordion.Header {...stepProps.step(3).header}>
                  <IconTextWrapper>
                    <IdIcon />
                    {isValidStep(3) || isActiveStep(3) ? (
                      <Text
                        weight={isStepDisabled(3) ? 'medium' : 'bold'}
                        textAlign='center'
                        css={isActiveStep(3) ? styles.accordionHeader : styles.noMargin}
                      >
                        {t('Billing Information')}
                      </Text>
                    ) : (
                      <Text color='light' css={styles.noMargin}>
                        {t('Enter billing information')}
                      </Text>
                    )}
                  </IconTextWrapper>
                </Accordion.Header>
                <Accordion.Body css={styles.addressElementContainer}>
                  <AddressElement options={{ mode: 'billing' }} onChange={getCardName} />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <div css={styles.buttonContainer}>
              <div css={styles.submit}>
                {!isTTP && <ReCAPTCHA ref={recaptchaRef} sitekey={reCaptchaKey} onChange={handleCaptcha} />}
                <PrimaryButton {...confirmTriggerProps} disabled={disableSubmit} className='submitButton' size='large'>
                  {paymentSuccessfullyCompleted ? t('Payment Submitted') : t('Submit Payment')}
                </PrimaryButton>
              </div>
              <div className='legalItems'>
                <WeavePoweredLogo />
                <StripeIcon css={styles.stripeIcon} />
              </div>
            </div>
          </>
        )}
      </div>
      <div css={styles.footer}>
        <a href='https://stripe.com/us/privacy' target='_blank' rel='noopener noreferrer'>
          {t('Privacy Policy')}
        </a>
      </div>
      {/* Modals */}
      <ConfirmationModal
        {...confirmModalProps}
        title={t('Confirm Payment')}
        onConfirm={async () => await handleSubmit()}
        cancelLabel='Continue Editing'
        confirmLabel='Submit Payment'
        maxWidth={500}
      >
        <div css={styles.confirmContainer}>
          <Text css={styles.confirmKeys}>{t('Payment amount')}</Text>
          <Text css={styles.confirmData}>{displayAmount(payment?.amount || 0)}</Text>
          <Text css={styles.confirmKeys}>{t(`Patient's Name`)}</Text>
          <Text css={styles.confirmData}>{`${payment.patientName?.firstName} ${payment.patientName?.lastName}`}</Text>
          <Text css={styles.confirmKeys}>{t('Email')}</Text>
          <Text css={styles.confirmData}>{payment.receiptEmail || t('Not provided')}</Text>
        </div>
      </ConfirmationModal>
      {/* TODO: see if we need this */}
      {/* <Modal {...replaceCardOnFileModalProps.modalProps}>
        <Modal.Header>{t('Replace Card on File')}</Modal.Header>
        <Modal.Body>
          <Text>
            <Trans t={t}>
              Saving the {' TODO: card type'} {'card number'} will replace the {'old card type'} {'old card number'}{' '}
              that's currently on file.
            </Trans>
          </Text>
        </Modal.Body>
        <Modal.Actions
          onSecondaryClick={replaceCardOnFileModalProps.closeModal}
          onPrimaryClick={() => {
            alert(t('Confirm save card on file'));
          }}
          primaryLabel={t('Confirm')}
          secondaryLabel={t('Cancel')}
        />
      </Modal> */}
      <FullscreenModal {...attachmentAuthModalProps}>
        <FullscreenModal.Header title={t('Authentication Required')}></FullscreenModal.Header>
        <FullscreenModal.Body>
          <Auth
            closeModal={closeAttachmentAuthModal}
            personName={requestForPayment?.personName}
            link={requestForPayment?.attachment ? requestForPayment?.attachment : ''}
          />
        </FullscreenModal.Body>
      </FullscreenModal>
    </div>
  );
};

export default PaymentPortal;

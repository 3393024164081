import { useMemo } from 'react';
import {
  ModuleRow,
  TaskStatus,
} from '@weave/schema-gen-ts/dist/schemas/insys/onboarding/v1/onboarding-tasks/onboarding_tasks.pb';
import { OnboardingProgressStatus } from '@weave/schema-gen-ts/dist/shared/insys/activation/onboarding.pb';
import { chunk } from 'lodash-es';
import { MutationOptions, UseQueryOptions } from 'react-query';
import { logOnboardingSentryError } from '@frontend/api-intake-form';
import { CoreACLs, getDecodedWeaveToken, getUser, hasACL } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { useLocationDataShallowStore, useLocalizedQuery } from '@frontend/location-helpers';
import { useMutation, useQuery } from '@frontend/react-query-helpers';
import { SchemaOnboardingProgressService, SchemaOnboardingTasksService } from '@frontend/schema';
import { useAppScopeStore } from '@frontend/scope';
import { sentry } from '@frontend/tracking';
import {
  getModulesAndTasksByLocationId,
  getSteps,
  getModulesAndTasksForAllLocations,
  getTasksByUserId,
  getLatestOnboardingProgressByLocationID,
} from './api';
import { prepareOnboardingModuleListInfo, getCompletedTasksPercentage } from './helpers';
import {
  IsUserSetupCompleteRequest,
  IsUserSetupCompleteResponse,
  ResendUserInviteRequest,
  ResendUserInviteResponse,
  TaskCompletionType,
  TaskTrayStepInfo,
} from './types';

const onboardingModulesQueryKeys = {
  base: ['onboarding-modules'] as const,
  modulesAndTasks: (locationId: string) => [...onboardingModulesQueryKeys.base, 'modules-and-tasks', locationId],
  taskSteps: (taskId: number) => [...onboardingModulesQueryKeys.base, taskId, 'task-steps'],
  dashboardTaskPercentage: () => [...onboardingModulesQueryKeys.base, 'dashboard-task-percentage'],
  latestOnboardingProgressStatus: (locationId: string) => [
    ...onboardingModulesQueryKeys.base,
    'latest-onboarding-progress-status',
    locationId,
  ],
  fetchUserSetupStatus: (email: string) => [...onboardingModulesQueryKeys.base, 'user-setup-status', email],
};

export const useGetModulesAndTasksByLocationId = (locationId: string, options?: UseQueryOptions<ModuleRow[]>) => {
  return useQuery({
    queryKey: onboardingModulesQueryKeys.modulesAndTasks(locationId),
    queryFn: () => getModulesAndTasksByLocationId(locationId),
    ...options,
    enabled: !!locationId && (options?.enabled ?? true),
    retry: false,
    onError(error) {
      logOnboardingSentryError('Error fetching onboarding modules and tasks data.', error);
      console.error('an error occurred while calling tasks api of onboarding-modules', error);
    },
  });
};

export const useModulesAndTasksQuery = (isReviewsEnabled: boolean) => {
  const { locationId, isParentLocation } = useLocationDataShallowStore('locationId', 'isParentLocation');
  const canEditUser = hasACL(locationId, CoreACLs.USER_WRITE);

  const { isLoading, isError, data: modules } = useGetModulesAndTasksByLocationId(locationId);

  const moduleListInfo = useMemo(() => {
    if (isLoading || isError || !modules?.length) return undefined;

    return prepareOnboardingModuleListInfo(modules, {
      canEditUser,
      isParentLocation,
      isReviewsEnabled,
    });
  }, [isLoading, isError, modules]);

  return { isLoading, isError, moduleListInfo };
};

export const useDashboardTaskPercentage = (chunkCount = 3) => {
  const { selectedLocationIds } = useAppScopeStore();
  const {
    isLoading,
    isError,
    data: modules,
  } = useQuery({
    queryKey: [...selectedLocationIds, onboardingModulesQueryKeys.dashboardTaskPercentage()],
    queryFn: () => getModulesAndTasksForAllLocations({ selectedIds: selectedLocationIds }),
    retry: false,
  });

  const moduleListInfo = useMemo(() => {
    if (isLoading || isError || !modules?.length) return undefined;

    const mappedModules = modules.map((module) => {
      return {
        locationId: module.locationId,
        percentage: getCompletedTasksPercentage(module.modules),
      };
    });

    return chunk(mappedModules, chunkCount);
  }, [isLoading, isError, modules, chunkCount]);

  return { isLoading, isError, moduleListInfo };
};

export const useIsSelectedTaskCompletedQuery = (moduleId: number, taskId: number) => {
  const { selectedLocationIdsWithParents } = useAppScopeStore();
  const selectedLocationId = selectedLocationIdsWithParents?.[0] ?? '';

  const {
    isFetching,
    isError,
    data: modules,
    refetch,
  } = useQuery({
    queryKey: onboardingModulesQueryKeys.modulesAndTasks(selectedLocationId),
    queryFn: () => getModulesAndTasksByLocationId(selectedLocationId),
    enabled: false,
    retry: false,
    onError(error) {
      console.error('an error occurred while calling tasks api of onboarding-modules', error);
    },
  });

  const isTaskCompleted = useMemo(() => {
    if (isFetching || isError || !modules?.length) return undefined;

    return (
      modules.find((module) => module.id === moduleId)?.tasks.find((task) => task.id === taskId)?.status ===
      TaskStatus.COMPLETE
    );
  }, [isFetching, isError, modules]);

  return { isLoading: isFetching, isError, refetch, isTaskCompleted };
};

export const useTaskStepsQuery = (taskId: number | null) => {
  const { t } = useTranslation('onboarding-modules');
  const { locationId } = useLocationDataShallowStore('locationId');
  const { isLoading, isError, refetch, data } = useLocalizedQuery({
    queryKey: onboardingModulesQueryKeys.taskSteps(taskId as number),
    queryFn: () => getSteps(taskId as number),
    enabled: !!taskId && !!locationId,
    retry: false,
    onError(error) {
      console.error('an error occurred while calling steps api of onboarding-modules', error);
    },
  });

  const steps = useMemo(() => {
    if (isLoading || isError || !data?.length) return [];

    const totalStep = data.length;
    return data.map<TaskTrayStepInfo>(({ id, description, information, imageUrl }, index) => ({
      id,
      description,
      information,
      imageUrl,
      title: t('Step {{currentStep}} of {{totalStep}}', {
        currentStep: index + 1,
        totalStep,
      }),
    }));
  }, [isLoading, isError, data]);

  return { refetch, isLoading, isError, steps };
};

export const useMarkLocationTaskCompletedMutation = () => {
  const { mutate, isLoading, isError, isSuccess } = useMutation({
    mutationFn: (taskId: number) =>
      SchemaOnboardingTasksService.MarkLocationTaskCompleted({ taskId, completionType: TaskCompletionType.Modules }),
    onError: (error, taskId) => {
      sentry.error({
        error: 'Failed to mark location onboarding task as complete',
        topic: 'onboarding',
        addContext: {
          name: 'details',
          context: {
            taskId,
          },
        },
      });
      console.error(error);
    },
  });

  return { mutate, isLoading, isError, isSuccess };
};

export const useFetchOnboardingStatusQuery = (locationIds: string[]) => {
  const orderedLocationIds = locationIds.sort();

  return useQuery({
    queryKey: ['onboarding-status', ...orderedLocationIds],
    queryFn: () => SchemaOnboardingTasksService.GetOnboardingStatusByLocationIDs({ locationIds }),
    enabled: !!locationIds.length,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
  });
};

export const useFetchUserTasksQuery = () => {
  const { selectedLocationIdsWithParents } = useAppScopeStore();
  const locationId = selectedLocationIdsWithParents[0];
  const user = getUser();

  const tokenUserId = getDecodedWeaveToken()?.user_id ?? '';
  const mismatchUserId = user?.userID !== tokenUserId;
  if (mismatchUserId) {
    sentry.warn({
      error: 'UserId mismatch. LocalStorage UserId different than token user_id. ID: ONB-jd9487rh',
      topic: 'onboarding',
      addContext: {
        name: 'details',
        context: {
          userId: user?.userID,
          tokenUserId,
        },
      },
    });
  }

  return useQuery({
    queryKey: [locationId, 'user-tasks'],
    queryFn: () => {
      return getTasksByUserId(tokenUserId);
    },
    enabled: !!locationId && !!tokenUserId,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
  });
};

export const useGetLatestOnboardingProgressByLocationID = (
  locationId: string,
  options?: UseQueryOptions<OnboardingProgressStatus>
) => {
  return useQuery({
    queryKey: onboardingModulesQueryKeys.latestOnboardingProgressStatus(locationId),
    queryFn: () => getLatestOnboardingProgressByLocationID(locationId),
    ...options,
    enabled: !!locationId && (options?.enabled ?? true),
  });
};

export const useResendUserInviteEmail = (
  options?: MutationOptions<ResendUserInviteResponse, unknown, ResendUserInviteRequest>
) => {
  return useMutation({
    mutationFn: (request: ResendUserInviteRequest) => {
      return SchemaOnboardingProgressService.ResendUserInviteEmail(request, { skipValidation: true });
    },
    ...options,
  });
};

export const useIsUserSetupComplete = (
  request: IsUserSetupCompleteRequest,
  options?: UseQueryOptions<IsUserSetupCompleteResponse>
) => {
  return useQuery({
    queryKey: onboardingModulesQueryKeys.fetchUserSetupStatus(request?.userEmail),
    queryFn: () => SchemaOnboardingProgressService.IsUserSetupComplete(request, { skipValidation: true }),
    retry: false,
    ...options,
    enabled: !!request.userEmail && (options?.enabled ?? true),
  });
};

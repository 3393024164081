import { Dispatch, SetStateAction } from 'react';
import { css } from '@emotion/react';
import { Button } from '@frontend/generic-dialpad-accessories';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { useSoftphoneEventsEmitter } from '../../providers/softphone-events-provider';

type RatingsType = 'up' | 'down';

export const SoftphoneFeedbackCallRating = ({
  setOpen,
  openModal,
}: {
  setOpen: Dispatch<SetStateAction<boolean>>;
  openModal: () => void;
}) => {
  const { t } = useTranslation('phone');
  const { emit } = useSoftphoneEventsEmitter();

  const handleFeedback = (rating: RatingsType) => {
    if (rating === 'up') {
      emit('call.feedback_requested', { callRating: 'positive', callID: '' });
    } else {
      openModal();
    }
    setOpen(false);
  };

  const closeFeedback = () => {
    setOpen(false);
    emit('call.feedback_requested', {
      callRating: 'dismiss',
      callID: '',
    });
  };

  return (
    <>
      <div
        css={css`
          display: grid;
          grid-template-columns: 1fr auto;
          gap: ${theme.spacing(2, 1)};
          padding: ${theme.spacing(1)};
        `}
      >
        <Text size='medium' color='white' weight='regular'>
          {t('How was your experience with this call')}?
        </Text>
        <Icon css={{ margin: '2px 0', cursor: 'pointer' }} onClick={closeFeedback} name='x-small' color='white' />
        <div css={{ display: 'flex', gap: theme.spacing(1), margin: 'auto' }}>
          <Button onClick={() => handleFeedback('up')} css={buttonStyle}>
            <Text
              css={css`
                font-size: 24px;
              `}
            >
              👍
            </Text>
          </Button>
          <Button onClick={() => handleFeedback('down')} css={buttonStyle}>
            <Text
              css={css`
                font-size: 24px;
              `}
            >
              👎
            </Text>
          </Button>
        </div>
      </div>
    </>
  );
};

const buttonStyle = css`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { IconButton, Text } from '@frontend/design-system';
import { DEFAULT_NEW_CONVERSATION } from '../../constants';
import { useTeamChatStore } from '../../providers';
import { containerStyles, textStyle } from './chat-title-bar.styles';

export const ChatTitleBar = () => {
  const { t } = useTranslation('team-chat');
  const { initializeNewConversation } = useTeamChatStore(['initializeNewConversation']);

  return (
    <section css={containerStyles}>
      {/* FIXME: Commenting the code so that it can be picked up later for adding chat settings */}
      {/* <NakedButton css={textContainerStyle}> */}
      <Text css={textStyle}>{t('Team Chat')}</Text>
      {/* <Icon name='caret-down-tiny' /> */}
      {/* </NakedButton> */}
      <IconButton label='Draft' onClick={() => initializeNewConversation(DEFAULT_NEW_CONVERSATION)}>
        <Icon name='draft' />
      </IconButton>
    </section>
  );
};

import { FC } from 'react';
import { Icon } from '@frontend/icons';
import { IconButton, ModalControlModalProps } from '@frontend/design-system';
import { useTeamChatStore } from '../../providers';
import { trayTopBarStyles, rotateCollapseButton } from './tray-top-bar.styles';

interface TrayTopBarProps {
  modalProps: ModalControlModalProps;
}

export const TrayTopBar: FC<TrayTopBarProps> = ({ modalProps }) => {
  const { isChatListExpanded, setIsChatListExpanded, activeConversation } = useTeamChatStore([
    'isChatListExpanded',
    'activeConversation',
    'setIsChatListExpanded',
  ]);

  const onClickExpand = () => {
    // collapse the chat list only when a conversation is selected either a new or an old one
    setIsChatListExpanded(!isChatListExpanded);
  };

  return (
    <header css={[trayTopBarStyles, !activeConversation && { justifyContent: 'flex-end' }]}>
      {activeConversation && (
        <IconButton label='Collapse' onClick={onClickExpand} css={!isChatListExpanded && rotateCollapseButton}>
          <Icon name='collapse-right-small' />
        </IconButton>
      )}
      <IconButton label='close' onClick={modalProps.onClose}>
        <Icon name='x-small' />
      </IconButton>
    </header>
  );
};

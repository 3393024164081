import { FormEventHandler } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Button, Text } from '@frontend/design-system';

interface Props {
  disabled?: boolean;
  onClick: FormEventHandler;
}

export const SubmitButton = ({ disabled, onClick, ...rest }: Props) => {
  // Technically, it should be an IconButton, but with that it needs a lot of style override.
  const { t } = useTranslation('team-chat');
  return (
    <Button
      variant='primary'
      css={styles.button}
      disabled={disabled}
      onClick={onClick}
      size='small'
      trackingId='team-chat-v2-send-messge'
      {...rest}
    >
      <Text color='white'>{t('Send')}</Text>
      <Icon color='white' name='send' />
    </Button>
  );
};

const styles = {
  button: css`
    gap: ${theme.spacing(1)};
    svg {
      margin-left: 0;
    }
  `,
};
